import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

const Articles = () => (
  <>
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "//content/announcements/" } }
            limit: 4
            sort: { fields: id, order: ASC }
          ) {
            totalCount
            edges {
              node {
                frontmatter {
                  category
                  title
                  path
                  date(formatString: "DD.MM.YYYY")
                  center
                }
                html
                id
              }
            }
          }
        }
      `}
      render={(data) => {
        const {
          allMarkdownRemark: { edges, totalCount },
        } = data;
        return (
          <>
            <section className='section--articles' id='articole'>
              <div className='announcements'>
                {edges.length === 0 ? null : (
                  <>
                    {edges.map(({ node }) => {
                      const {
                        frontmatter: { title, category, center },
                        html,
                        id,
                      } = node;
                      return (
                        <div key={id} style={{ textAlign: 'center' }}>
                          {title && <h3>{title}</h3>}
                          <div
                            dangerouslySetInnerHTML={{ __html: html }}
                            className={center ? 'center' : ''}
                          />
                        </div>
                      );
                    })}
                    {totalCount > 4 && (
                      <Link to='/anunturi' className='button'>
                        Mai multe anunțuri
                      </Link>
                    )}
                  </>
                )}
              </div>
            </section>
          </>
        );
      }}
    />
  </>
);

export default Articles;
