import React from 'react';

const IEWarning = () => (
  <section
    id="IEWarning"
    className="section--ieWarning"
    style={{ textAlign: 'center' }}
  >
    <h3>
      Am observat că folosiți un browser care nu este compatibil cu pagina
      noastră
    </h3>
    <p>
      Vă rugăm să folosiți Microsoft Internet Explorer 11, Microsoft Edge,{' '}
      <a href="https://www.google.com/chrome/">Chrome</a> sau{' '}
      <a href="https://www.mozilla.org/en-US/firefox/">Firefox</a> pentru o
      experiență mai bună. Mulțumim.
    </p>
    <h4>
      <a href="https://curteadeapeltimisoara.eu/">
        Acces Info Dosar/Fișă Dosar
      </a>
    </h4>
  </section>
);

export default IEWarning;
