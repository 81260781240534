import React from "react";
import { Link } from "gatsby";

const DigitalCase = () => (
  <section className="section--digitalcase">
    <h4
      style={{
        marginTop: 0,
        marginBottom: "1em",
        fontWeight: "600",
      }}
    >
      <p>
        Stimați justițiabili,
        <br />
        Vă punem la dispoziție aplicația informatică Dosarul Electronic
        Național, în prezent aflată în faza de testare la nivelul instanțelor
        din circumscripția Curții de Apel Alba-Iulia, Curții de Apel București
        și Curții de Apel Timișoara.
      </p>
      <p>
        Aplicația poate fi accesată pe pagina
        <a target="_blank" href="https://den.just.ro">
          https://den.just.ro
        </a>
      </p>
      <p>
        Informații privind accesarea Dosarului Electronic Național pot fi
        obținute pe pagina{" "}
        <a target="_blank" href="https://den.just.ro/informatii">
          http://den.just.ro/informatii
        </a>
      </p>
    </h4>
  </section>
);

export default DigitalCase;
