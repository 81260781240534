import React from "react";
// import Layout from '../components/Layout';
import Cards from "../components/Cards";
import Showcase from "../components/Showcase";
import IEWarning from "../sections/IEWarning";
import DigitalCase from "../sections/DigitalCase";
import DigitalCase2 from "../sections/DigitalCase2";
// import Articles from '../sections/Articles';
import Announcements from "../sections/Announcements";

const IndexPage = ({ location }) => (
  <>
    <Showcase />
    <IEWarning />
    <DigitalCase />
    <Cards />
    <DigitalCase2 />
    {/* <Articles /> */}
    <Announcements />
  </>
);

export default IndexPage;
