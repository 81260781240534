import React from "react";
import { Link } from "gatsby";

const DigitalCase2 = () => (
  <section className="section--digitalcase">
    <div>
      <Link to="/dosarulelectronic">Instrucțiuni Acces Dosarul Electronic</Link>
    </div>
    <div>
      <Link to="/comunicareelectronica">
        Optați pentru comunicarea de către instanță a actelor de procedură și
        documentelor în format electronic
      </Link>
    </div>
  </section>
);

export default DigitalCase2;
