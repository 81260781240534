import React from 'react';

const Showcase = ({ title, subtitle }) => (
  <section className="section--showcase">
    {title && ( // TODO: Style the title and subtitle properly
      <h1>
        {title}
        {subtitle && <span>{subtitle}</span>}
      </h1>
    )}
  </section>
);

export default Showcase;
