import React from "react";
import Link from "./Link";
import { StaticQuery, graphql } from "gatsby";

const Card = ({ link, label, img, description, isNew }) => {
  return (
    <Link href={link} aria-label={`${label} ${description}`}>
      <div className="card" role="navigation">
        <div className="card__img">
          <img src={img} alt={`${label}-image`} className="img-svg" />
        </div>
        <div className="card__text">
          <h2>
            {label} {isNew ? <div className="isNew">Nou</div> : null}
          </h2>
          <p>{description}</p>
        </div>
      </div>
    </Link>
  );
};

const Cards = () => (
  <StaticQuery
    query={graphql`
      query cardsQuery {
        allDataJson {
          edges {
            node {
              cards {
                link
                label
                description
                img
                isNew
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const { cards } = data.allDataJson.edges[0].node;
      return (
        <section className="section--noMargin">
          <div className="section__cards">
            {cards.map((card) => (
              <Card key={card.label} {...card} />
            ))}
          </div>
        </section>
      );
    }}
  />
);

export default Cards;
